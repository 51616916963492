import React, { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { isEmpty, isNil, pathOr } from 'ramda';

import { useSiteMetadata } from '../hooks';
import { initIntersectionObserver } from '../utils/intersectionObserver';
import { IProject } from '../types/index.types';

import Layout from '../components/Layout/Layout';
import Intro from '../components/Intro/Intro';
import ProjectCoverAsset from '../components/ProjectCoverAsset/ProjectCoverAsset';
import ProjectAssetBlock from '../components/ProjectAssetBlock/ProjectAssetBlock';
import ProjectNavigation from '../components/ProjectNavigation/ProjectNavigation';

export interface ProjectDetailTemplateProps {
	data: {
		allContentfulProject: {
			edges: {
				node: {
					title: string,
					slug: string,
				}
			}
		}
	};
}

const renderCover = (cover: any): ReactElement => {
	const { desktop, mobile } = cover;

	return <ProjectCoverAsset mobile={mobile} desktop={desktop} fullscreen scroll />;
};

const renderAssetBlocks = (assets: any[]) => assets.map((asset: any, i) => <ProjectAssetBlock key={i} assetData={asset} />);

const renderProject = (project: IProject): ReactElement => {
	const { title, intro, tags, coverImage, assets } = project;

	return (
		<section>
			{!isNil(coverImage) && renderCover(coverImage)}
			<Intro title={title} intro={intro.json} tags={tags} />
			{!isEmpty(assets) && !isNil(assets) && renderAssetBlocks(assets)}
		</section>
	);
};

const ProjectDetailTemplate: React.FC<ProjectDetailTemplateProps> = (props): ReactElement | null => {
	const { name, tagline, description, language } = useSiteMetadata();
	const project = pathOr(null, ['data', 'contentfulProject'], props);
	const context = pathOr(null, ['pageContext'], props);

	useEffect(() => {
		initIntersectionObserver();
		return () => initIntersectionObserver();
	}, []);

	if (!isNil(project)) {
		return (
			<Layout>
				<Helmet
					title={`${pathOr('', ['title'], project)} | ${name}`}
					meta={[
						{ name: 'description', content: description },
						{ name: 'tagline', content: tagline },
					]}
				>
					<html lang={language} />
				</Helmet>
				{renderProject(project)}
				{!isNil(context) && <ProjectNavigation next={context.next} previous={context.previous}/>}
			</Layout>
		);
	}

	return null;
};

export default ProjectDetailTemplate;

export const pageQuery = graphql`
	query ProjectBySlug($slug: String!) {
		contentfulProject(slug: { eq: $slug }) {
			id
			slug
			title
			tags
			intro {
				json
			}
			coverImage {
				desktop {
					file {
						url
					}
					fluid(
						resizingBehavior: FILL,
						quality: 100
					) {
						...GatsbyContentfulFluid_withWebp
					}
				}
				mobile {
					file {
						url
					}
					fluid(
						resizingBehavior: FILL,
						quality: 100
					) {
						...GatsbyContentfulFluid_withWebp
					}
				}
			}
			assets {
				...on ContentfulProjectAssetBlockDouble {
					id
					doubleData: data {
						file {
							url
						}
						fluid(
							resizingBehavior: FILL,
							quality: 100
						) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
				... on ContentfulProjectAssetBlockSingle {
						id
						singleData: data {
							file {
								url
							}
							fluid(
								resizingBehavior: FILL,
								quality: 100
							) {
								...GatsbyContentfulFluid_withWebp
							}
						}
					}
			}
		}
	}
`;
