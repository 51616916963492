import React, { ReactElement } from 'react';
import { Asset } from 'contentful';
import { isEmpty, isNil, pathOr } from 'ramda';
import isImage from 'is-image';
import cx from 'classnames';

import Video from '../Video/Video';
import Image from '../Image/Image';

export interface ProjectAssetBlockProps {
	assetData: Asset;
}

// TODO: type
const renderAsset = (data: any, i?: number): ReactElement | null => {
	const id = pathOr('', ['id'], data);
	const url = pathOr(null, ['file', 'url'], data);

	if (isNil(url)) {
		return null;
	}

	return (
		<div className='m-project-asset-block__item' key={`${id}-${i}`} >
			{isImage(url) ? <Image image={data} /> : <Video video={data} />}
		</div>
	);
};

const ProjectAssetBlock: React.FC<ProjectAssetBlockProps> = ({ assetData}): ReactElement => {
	const DOUBLE = 'ContentfulProjectAssetBlockDouble';
	const SINGLE = 'ContentfulProjectAssetBlockSingle';

	const type = pathOr(null, ['__typename'], assetData);
	const single = pathOr(null, ['singleData'], assetData);
	const double = pathOr(null, ['doubleData'], assetData);

	return (
		<div className={cx('m-project-asset-block', type === DOUBLE && 'm-project-asset-block--dual')}>
			{type === SINGLE && !isNil(single) && renderAsset(single)}
			{type === DOUBLE && !isNil(double) && !isEmpty(double) && double.map((asset: Asset, i: number) => renderAsset(asset, i))}
		</div>
	);
};

export default ProjectAssetBlock;
