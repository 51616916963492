import { IProjectNavigationItem } from '@/types/index.types';
import { isNil } from 'ramda';
import React, { ReactElement } from 'react';
import TransitionLink from '../TransitionLink/TransitionLink';

export interface ProjectNavigationProps {
	next: IProjectNavigationItem | null;
	previous: IProjectNavigationItem | null;
}

const renderLink = (slug: string, label: string, side: string): ReactElement => (
	<TransitionLink to={`/work/${slug}`} cn={`a-link u-heading-small m-project-navigation__${side}`}>{label}</TransitionLink>
);

const ProjectNavigation: React.FC<ProjectNavigationProps> = ({ next, previous }): ReactElement => (
	<section className='m-project-navigation u-wrapper--content'>
		{!isNil(previous) && renderLink(previous.slug, 'Previous', 'prev')}
		{!isNil(next) && renderLink(next.slug, 'Next', 'next')}
	</section>
);

export default ProjectNavigation;
