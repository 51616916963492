import React, { ReactElement } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RichTextContent } from 'contentful';

export interface IntroProps {
	title: string;
	intro: RichTextContent;
	tags: string[];
}

const Intro: React.FC<IntroProps> = ({ title, intro, tags }): ReactElement => (
	<article className='o-intro u-wrapper--content' id='projectContent'>
		<header className='o-intro__header'>
			<h2 className='o-intro__title u-heading-xlarge' data-inview data-animatein='reveal'>{title}</h2>
		</header>
		<div className='o-intro__body'>
			<div className='o-intro__content' data-inview data-animatein='default'>{documentToReactComponents(intro)}</div>
			<ul className='o-intro__tag-list' data-inview data-animatein='stagger'>
				{tags.map((tag: string) => (
					<li className='o-intro__tag' key={tag}>
						<p className='o-intro__tag-text stagger'>{tag}</p>
					</li>
				))}
			</ul>
		</div>
	</article>
);

export default Intro;
